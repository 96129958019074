.login-button {
  border: 2px solid #fff95b;
  color: #fff95b;
  padding: 8px 15px;
}
.login-button:hover {
  transition: 0.7s ease-in-out;
  background-color: #fff95b;
  color: #000;
}
#form-title {
  color: #fff95b;
}
#tab {
  color: #fff95b;
}
.tab_hover:hover {
  color: #fff;
}

.nav-link:hover {
  color: #fff;
  transition: 1s ease-in-out;
}
